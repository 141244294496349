import { QXYVOIDE_ENV } from "@/config/setting"
import store from '@/store';
import { errorLogWrite } from "@/api/public/index"
/**
 * @description: 深拷贝对象
 * @param {Object} obj
 * @author: xuhao
 */
export function deepCopy(obj) {
    if(obj){
        return JSON.parse(JSON.stringify(obj))
    } else {
        return ''
    }
}
/**
 * @description: 获取摄像头对象
 * @param {Object} video
 * @author: xuhao
 */
export function openCamera(video) {
    let mediaStreamTrack = []
    let acquiringTimes = 0
    let result = {
        _takePhoto: function() {
            return new Promise((resolve, err) => {
                navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
                    mediaStreamTrack.push(stream)
                    let canvas = document.createElement('canvas')
                    canvas.width = video.videoWidth | 172;
                    canvas.height = video.videoHeight | 172;
                    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
                    compressImg(canvas.toDataURL(), 0.8, (e) => {
                        resolve(e)
                    })
                }).catch(() => {
                    err(new Error("摄像头获取异常"))
                });
            })
        },
        _takeStop: function() {
            mediaStreamTrack.forEach(item => {
                const videoTracks = item?.getVideoTracks();
                videoTracks.forEach(track => track.stop());
            })
        }
    }
    function init() {
        acquiringTimes++
        return new Promise((req, err) => {
            navigator.mediaDevices.getUserMedia({video: { mimeType: 'video/webm;codecs=vp8,opus' }}).then(function(stream) {
                mediaStreamTrack.push(stream)
                if(stream.active || acquiringTimes > 5) {
                    video.srcObject = stream
                    video.play()
                    req(result)
                } else {
                    init().then(res => {
                        req(res)
                    }).catch(() => {
                        err(new Error("摄像头获取异常"))
                    })
                }
            }).catch((e) => {
                console.error(e)
                err(new Error("摄像头获取异常"))
            }) 
        })
    }
    return init()
}
/**
 * @description: 打开全屏
 * @author: xuhao
 */
export function fullScreen() {
    try{
        // 获取要全屏显示的元素
        const element = document.documentElement;
        // 进入全屏模式
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) { // 兼容 Firefox
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) { // 兼容 Chrome, Safari 和 Opera
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) { // 兼容 Edge
            element.msRequestFullscreen();
        }
    }catch(e) {
        console.warn(e)
    }
}
/**
 * @description: 压缩图片
 * @author: xuhao
 */
export function compressImg (base64, multiple, callBack) {
    // 第一个参数就是需要加密的base65，
    // 第二个是压缩系数 0-1，
    // 第三个压缩后的回调 用来获取处理后的 base64
    if (!base64) {
      return
    }
    // const _this = this
    const length = base64.length / 1024
    // 压缩方法
    let newImage = new Image()
    let quality = 0.8    // 压缩系数0-1之间
    newImage.src = base64
    newImage.setAttribute('crossOrigin', 'Anonymous') // url为外域时需要
    let imgWidth,
        imgHeight
    let w = undefined
    newImage.onload = function () {
      // 这里面的 this 指向 newImage
      // 通过改变图片宽高来实现压缩
      w = this.width * multiple
      imgWidth = this.width
      imgHeight = this.height
      let canvas = document.createElement('canvas')
      let ctx = canvas.getContext('2d')
      if (Math.max(imgWidth, imgHeight) > w) {
        if (imgWidth > imgHeight) {
          canvas.width = w
          // 等比例缩小
          canvas.height = w * (imgHeight / imgWidth)
        } else {
          canvas.height = w
          // 等比例缩小
          canvas.width = w * (imgWidth / imgHeight)
        }
      } else {
        canvas.width = imgWidth
        canvas.height = imgHeight
        // quality 设置转换为base64编码后图片的质量，取值范围为0-1  没什么压缩效果
        // 还是得通过设置 canvas 的宽高来压缩
        quality = 1
      }
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.drawImage(this, 0, 0, canvas.width, canvas.height) //  // 这里面的 this 指向 newImage
      let smallBase64 = canvas.toDataURL('image/jpeg', quality) // 压缩语句
      // 如想确保图片压缩到自己想要的尺寸,如要求在50-150kb之间，请加以下语句，quality初始值根据情况自定
      // while (smallBase64.length / 1024 > 150) {
      // quality -= 0.01;
      // smallBase64 = canvas.toDataURL("image/jpeg", quality);
      // }
      // 防止最后一次压缩低于最低尺寸，只要quality递减合理，无需考虑
      // while (smallBase64.length / 1024 < 50) {
      // quality += 0.001;
      // smallBase64 = canvas.toDataURL("image/jpeg", quality);
      // }
   
      // 必须通过回调函数返回，否则无法及时拿到该值，回调函数异步执行
      console.log(`压缩前：${length}KB`)
      console.log(`压缩后：${smallBase64.length / 1024} KB`)
      callBack(smallBase64)
    }
  }
/**
 * @description: 秒转化为 hh:mm:ss
 * @param {String, Number} s
 * @author: xuhao
 */
export function formatSeconds(seconds) {
    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor((seconds - (hours * 3600)) / 60);
    var remainingSeconds = seconds - (hours * 3600) - (minutes * 60);

    // 将小时、分钟和秒转化为两位数格式
    var formattedHours = ("0" + hours).slice(-2);
    var formattedMinutes = ("0" + minutes).slice(-2);
    var formattedSeconds = ("0" + remainingSeconds).slice(-2);

    return formattedHours + ":" + formattedMinutes + ":" + formattedSeconds;
}

/**
 * @description: 视频初始化
 * @param {document} dom 播放元素
 * @param {string} token 播放token
 * @param {Boolean} playbackRateMenuButton 是否允许倍速播放
 * @param {Boolean} canseek 是否运行拖动进度条
 * @param {Function} onPlay 播放回调
 * @param {Function} onPause 暂停观看
 * @param {Function} onEnd 结束回调
 * @author: xuhao
 */
export function initializePlayer(dom, token, playbackRateMenuButton, canseek, onPlay = () => {}, onPause = () => {}, onEnd = () => {}, onProgressSliderOperational, onMediaInfo = () => {}) {
    // function getQueryVariable(variable) {
    //     var query = window.location.search.substring(1);
    //     var vars = query.split("&");
    //     for (var i = 0; i < vars.length; i++) {
    //         var pair = vars[i].split("=");
    //         if (pair[0] == variable) { return pair[1]; }
    //     }
    //     return (false);
    // }
    // let canseek = getQueryVariable('canseek') == '1' ? false : true
    let _token = token
    if (!_token) {
        alert('请传入token')
    }
    //.video-center 是div的类选择器， 如果是id选择器， 则传入'#video-center'
    const qxcRecPlayerUISDK = new window.QXCRecPlayerUISDK(dom, {
        // 鉴权token
        token: _token, // '20a969e8db3d06a87e84|0|1678180128|1678180128888074720|1|mg3ic3f37326K2vca|200',
        // 用户名，可不填
        name: 'name',
        // 环境  test 测试环境  product 正式环境  默认是正式环境
        env: QXYVOIDE_ENV,
        // 打印日志 4相当于debug， 设置0或则不设置值，不会打印日志，
        logLeve: 0,
        // 海报封面
        poster: '',
        // 显示控制界面
        controls: true,
        // 自动播放, 第一次播放可能会不成功
        autoplay: false,
        // 自定义控制栏
        controlBar: {
            // 显示音量控制
            showVolume: true,
            // 显示当前时间
            currentTimeDisplay: true,
            // 显示总时间
            durationDisplay: true,
            // 显示剩余时间
            remainingTimeDisplay: false,
            // 显示全屏按钮
            fullscreenToggle: true,
            // 显示倍速按钮
            playbackRateMenuButton,
            // 键盘快进退时间，默认5秒
            quickTime: 5,
            // 进度条控制
            progressControl: {
                // seekbar控制
                seekbar: {
                    // 是否可seek
                    enableSeek: canseek,
                    // 是否显示已播放的进度
                    playProgressBar: true,
                    // 鼠标滑过是否显示时间
                    mouseTimeDisplay: true,
                    // 是否显示已经观看的点
                    lookedTimeDisplay: true
                }
            }
        },
        // 以下所有回调方式，内部均已实现，外面如果使用不到，可忽略
        qxcPlayerCallback: {
            //异地观看
            onDuplicatePlay: function () {
                console.log('video_status onDuplicatePlay')
            },
            //错误信息
            onError: function (type, msg) {
                console.log('video_status onError', type, msg)
            },
            // 资源信息
            onMediaInfo: onMediaInfo,
            //开始观看
            onPlay: onPlay,
            //暂停观看
            onPause: onPause,
            //播放时间
            onTotalTime: function () {
                console.log('video_status onTotalTime')
            },
            onProgressSliderOperational: onProgressSliderOperational,
            // 播放完毕
            onEnd: onEnd
        }

    })
    return qxcRecPlayerUISDK
}
/**
 * @description: 下载文件
 * @param {String} filename 文件名
 * @param {Object}  接口范围的blob数据
 * @author: xuhao
 */
export function downloadFiles(filename, response) {
    const contentType = response.headers["content-type"];
    console.log(filename.split('.'))
    let filenameArr = filename.split('.')
    // if(filenameArr[filenameArr.length - 1]) {
    //     filenameArr[0] = filenameArr[0] + toDateString(new Date(), 'yyyyMMddHHmmss')
    // }
    filename = filenameArr.join('.')
    let blob = new Blob([response.data], {
        type: contentType
    })
    console.log(blob)
    const url = window.URL.createObjectURL(blob)
    let linkElement = document.createElement("a");
    linkElement.setAttribute("href", url);
    linkElement.setAttribute("target", '_blank');
    linkElement.setAttribute("download", filename);
        if (typeof MouseEvent == "function") {
            var event = new MouseEvent("click", {
                view: window,
                bubbles: true,
                cancelable: false
            });
            linkElement.dispatchEvent(event);
        }

}
/**
 * @description: 下载文件
 * @author: xuhao
 */
export function downloadFileByUrl(url, fileName) {
    return new Promise(req => {
        getBlob(url).then(res => {
            const blob = new Blob([res], { type: 'application/octet-stream' });
            if (window.navigator.msSaveOrOpenBlob) {
              // 兼容 Internet Explorer
              window.navigator.msSaveOrOpenBlob(blob, fileName);
            } else {
              // 创建临时链接并设置属性
              const url = URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.download = fileName;
        
              // 触发点击事件进行下载
              link.dispatchEvent(new MouseEvent('click'));
        
              // 释放创建的临时链接
              URL.revokeObjectURL(url);
              req()
            }
        })
    })
}
/**
 * @description: 文件地址转文件流
 * @author: xuhao
 */
export function getBlob(url) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url);
        xhr.responseType = 'blob';
    
        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject(new Error(`Unable to convert URL to blob: ${xhr.status}`));
          }
        };
    
        xhr.onerror = () => {
          reject(new Error('Network request failed'));
        };
    
        xhr.send();
      });
}

  /**
     * @description: 时间格式化
     * @author: xuhao
     */
  export function formatDate(date, format) {
    const d = new Date(date)
    
    const placeholders = {
      'YYYY': d.getFullYear(),
      'MM': ('0' + (d.getMonth() + 1)).slice(-2),
      'DD': ('0' + d.getDate()).slice(-2),
      'HH': ('0' + d.getHours()).slice(-2),
      'mm': ('0' + d.getMinutes()).slice(-2),
      'ss': ('0' + d.getSeconds()).slice(-2)
    }
    
    return format.replace(/YYYY|MM|DD|HH|mm|ss/g, match => placeholders[match])
  }
  /**
     * @description: 格式化日志
     *  @param {String} errMsg 错误信息
     * @param {String}  stack 错误信息位置
     * @param {String}  type 错误类型
     * @author: xuhao
    */
  export function errorLog(errMsg, stack, type, trace) {
    let globalStatus = store.getters.globalStatus
    let errInfo = {
        isLogin: Boolean(globalStatus.userInfo.id),
        errMsg: errMsg,
        errStack: stack,
        trace: trace,
        time: formatDate(new Date(), 'YYYY-MM-DD HH:mm:ss'),
        type: type,
        url: window.location.href
    }
    if(errInfo.isLogin) {
        errInfo.userInfo = globalStatus.userInfo
    }
    errorLogWrite(errInfo)
  }