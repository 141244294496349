import request from '@/utils/request';
/**
 * 获取个人信息
 * @param params
 * @returns {Promise<*>}
 */
export async function getUserInfo(params) {
    const res = await request.get('/user/info', {
      params
    });
    if (res.data.code === 200) {
      return res.data.data;
    }
    return Promise.reject(new Error(res.data.message));
}
/**
 * 获取考籍信息
 * @param params
 * @returns {Promise<*>}
 */
export async function getMajor(params) {
    const res = await request.get('/user/major', {
      params
    });
    if (res.data.code === 200) {
      return res.data.data;
    }
    return Promise.reject(new Error(res.data.message));
}
/**
 * 发送验证码
 * @param params
 * @returns {Promise<*>}
 */
export async function sendCode(data) {
    const res = await request.post('/sms/send', data);
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}
/**
 * 发送邮箱验证码
 * @param params
 * @returns {Promise<*>}
 */
export async function sendEmail(data) {
    const res = await request.post('/email/send', data);
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}
/**
 * 上传
 * @param params
 * @returns {Promise<*>}
 */
export async function uploadFileImg(file) {
    const formData = new FormData();
    formData.append('images', file);
    const res = await request.post('/upload/images', formData);
    if (res.data.code === 200) {
      return res.data.data;
    }
    return Promise.reject(new Error(res.data.message));
  }
/**
 * 修改头像
 * @param params
 * @returns {Promise<*>}
 */
export async function editHeadimg(data) {
    const res = await request.post('/editHeadimg', data);
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}
/**
 * 修改头像
 * @param params
 * @returns {Promise<*>}
 */
export async function getConfig(data) {
    const res = await request.get('/get/config', { data });
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}
/**
 * 友情链接
 * @param params
 * @returns {Promise<*>}
 */
export async function getLink(data) {
    const res = await request.get('/get/link', { data });
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}
/**
 * 用户协议
 * @param params
 * @returns {Promise<*>}
 */
export async function getAgreement(data) {
  const res = await request.get('/get/agreement', { data });
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}
/**
 * 隐私政策
 * @param params
 * @returns {Promise<*>}
 */
export async function getPolicy(data) {
  const res = await request.get('/get/policy', { data });
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}

/**
 * 隐私政策
 * @param params
 * @returns {Promise<*>}
 */
export async function getCaptcha(data) {
  const res = await request.get('/get/captcha', { data });
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}

/**
 * 获取考试数量
 * @param params
 * @returns {Promise<*>}
 */
export async function getExam(data) {
	const res = await request.get('/get/exam', { data });
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}
/**
 * 网站维护
 * @param params
 * @returns {Promise<*>}
 */
export async function getRepair(data) {
	const res = await request.get('/get/repair', { data });
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}
/**
 * 记录前端报错信息
 * @param params
 * @returns {Promise<*>}
 */
export async function errorLogWrite(data) {
	const res = await request.post('/error_log/write', data);
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}

/**
 * 记录前端报错信息
 * @param params
 * @returns {Promise<*>}
 */
export async function getHints(data) {
	const res = await request.get('/get/remind', { data });
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}
