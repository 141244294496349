/*
 * @Description: 
 * @Version: 1.0.0
 * @Author: xuhao
 * @LastEditors: xuhao
 */
import { TOKEN_STORE_NAME } from '@/config/setting';
import { getUserInfo, getMajor, getConfig, getLink, getAgreement, getPolicy, getRepair, getHints } from "@/api/public/index"
import Vue from 'vue'
export default {
    state: {
        userInfo: {},
        major: localStorage.getItem('major') ? JSON.parse(localStorage.getItem('major')) : {},
        majorList: localStorage.getItem('majorList') ? JSON.parse(localStorage.getItem('majorList')) : {},
        // 是否阅读了剩余考试
        isReadSurplus: localStorage.getItem('isReadSurplus') ? localStorage.getItem('isReadSurplus') : false,
        nativePlace: {},
        config: {},
        link: [],
        agreement: "",
        privacy: '',
        repair: {},
        // 全局提示文字
        remind: {}
    },

    mutations: {
        setRemind(state, data) {
            state.remind = data
        },
        setRepair(state, data) {
            state.repair = data
        },
        setIsReadSurplus(state, data) {
            state.isReadSurplus = data
            localStorage.setItem('isReadSurplus', data)
        },
        setLink(state, data){
            state.link = data
        },
        setConfig(state, data) {
            document.title = data.title
            state.config = data
        },
        setMajorList(state, data) {
            state.majorList = data
            localStorage.setItem('majorList', JSON.stringify(data))
        },
        loginOut(state) {
            state.userInfo = {}
            state.major = {}
            state.isReadSurplus = false
            const websocket = Vue.prototype.$socket;
            websocket?.close()
            localStorage.removeItem('isReadSurplus')
            localStorage.removeItem('userInfo')
            localStorage.removeItem('major')
            localStorage.removeItem(TOKEN_STORE_NAME)
            // 退出登录关闭摄像头
            // try{
            //     navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
            //         debugger
            //         stream.getVideoTracks().forEach(track => {
            //             track.stop()
            //         })
            //     })
            // } catch(e) {
            //     console.warn("没有摄像头跳过关闭")
            // }
            
        },
        setMajor(state, data) {
            state.major = data
            localStorage.setItem('major', JSON.stringify(data));
        },
        setUserInfo(state, data) {
            state.userInfo = data
        },
        setNativePlace(state, data) {
            state.nativePlace = data
        },
        getAgreement(state, data) {
            state.agreement = data
        },
        getPolicy(state, data) {
            state.privacy = data
        }
    },
    actions: {
        setRemind({ commit }) {
            return new Promise((resolve, reject) => {
                getHints().then(res => {
                    commit('setRemind', res)
                    resolve()
                }).catch(() => {reject()})
                return getHints()
            })
        },
        setRepair({ commit }) {
            return new Promise((resolve, reject) => {
                getRepair().then((res) => {
                    // res.is_enable = '2'
                    commit('setRepair', res)
                    resolve()
                }).catch(() => {reject()})
            })
            
        },
        getPolicy({ commit }) {
            return new Promise((resolve, reject) => {
                getPolicy().then(res => {
                    commit('getPolicy', res.content)
                    resolve()
                }).catch(() => {reject()})
            })
        },
        getAgreement({ commit }) {
            return new Promise((resolve, reject) => {
                getAgreement().then(res => {
                    commit('getAgreement', res.content)
                    resolve()
                }).catch(() => {reject()})
            })
        },
        setLink({ commit }) {
            return new Promise((resolve, reject) => {
                getLink().then(res => {
                    commit('setLink', res)
                    resolve()
                }).catch(() => {reject()})
            })
            
        },
        setConfig({ commit }) {
            return new Promise((resolve, reject) => {
                getConfig().then(res => {
                    commit('setConfig', res)
                    resolve()
                }).catch(() => {reject()})
            })
            
        },
        loginOut({ commit }) {
            commit('loginOut')
        },
        async setUserInfo({ commit }) {
            return new Promise((resolve, reject) => {
                Promise.all([getUserInfo(), getMajor({ user_major_id: this.state.globalStatus.major.id})]).then(res => {
                    let result1 = res[0]
                    let result2 = res[1]
                    commit('setUserInfo', result1)
                    commit('setNativePlace', result2)
                    resolve(res)
                }).catch(() => {reject()})
            })
           
        }
    }
}