/*
 * @Description: 
 * @Version: 1.0.0
 * @Author: xuhao
 * @LastEditors: xuhao
 */
import { ORDERTYPE } from "@/enum/index"
export default {
    globalStatus: (state) => state.globalStatus,
    dictionary: (state) => {
        let result = state.dictionary
        if(state.globalStatus.major.cengci === '本科') {
            result.ORDERTYPE = [
                {
                    title: '课程订单',
                    value: ORDERTYPE.COURSEORDERS
                },
                {
                    title: '论文订单',
                    value: ORDERTYPE.PAPERORDER
                }
            ]
        } else {
            result.ORDERTYPE = [
                {
                    title: '课程订单',
                    value: ORDERTYPE.COURSEORDERS
                }
            ]
        }
        return result
    }
}