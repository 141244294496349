<!--
 * @Description: 考试提醒
 * @Version: 1.0.0
 * @Author: xuhao
 * @LastEditors: xuhao
-->
<template>
    <div>
        <el-dialog
            title="提示"
            custom-class="custom-dialog"
            :visible="show"
            :close-on-press-escape="false"
            :close-on-click-modal="false"
            :destroy-on-close="false"
            :show-close="false"
            width="527px"
            center>
            <div class="PublickPrompt">
                <div>检测到当前连接不正常,请检查网络</div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button class="custom-button" style="width: 108px;" type="primary" @click="loginOut" round>我知道了</el-button>
            </span>
        </el-dialog>
    </div>
    
</template>
<script>
// import store from '@/store';
import router from '@/router/index'
export default {
    name: 'LoginOut',
    props: {
        // show: {
        //     type: Boolean,
        //     default: false
        // }
    },
    components: {
    },
    data() {
        return {
            show: true,
            countdown: 0
        }
    },
    mounted() {
        
    },
    beforeDestroy() {
    },
    methods: {
        open() {
            console.log(this.show)
            this.show = true
        },
        close() {
            this.show = false
        },
        loginOut() {
            if(router.history.current.name !== 'examinationCenter') {
                router.push({ name: 'examinationCenter' })
            }
            this.show = false
        }
    }
}
</script>
<style lang="less" scoped>
    .PublickPrompt{
        font-size: 16px;
        color: #646464;
        text-align: center;
    }
</style>