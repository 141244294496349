/*
 * @Description: elementui 按需导入
 * @Version: 1.0.0
 * @Author: xuhao
 * @LastEditors: xuhao
 */
// 导入自己需要的组件
import {
    DropdownMenu,
    DropdownItem,
    Dropdown,
    Pagination,
    Dialog,
    Button,
    Radio,
    RadioGroup,
    Progress,
    Form,
    FormItem,
    Image,
    Message,
    upload,
    Empty,
    Loading,
    Checkbox,
    CheckboxGroup,
    Carousel,
    CarouselItem,
    Select,
    Option,
    Table,
    Input,
    TableColumn,
    Collapse,
    CollapseItem,
    Tooltip,
    MessageBox
} from 'element-ui'
const element = {
install: (Vue) => {
    const LoadingCopy = Loading
    Vue.use(Dropdown)
    Vue.use(DropdownMenu)
    Vue.use(DropdownItem)
    Vue.use(Pagination)
    Vue.use(Dialog)
    Vue.use(Button)
    Vue.use(Radio)
    Vue.use(RadioGroup)
    Vue.use(Progress)
    Vue.use(Form)
    Vue.use(FormItem)
    Vue.use(Image)
    Vue.use(upload)
    Vue.use(Empty)
    Vue.use(Checkbox)
    Vue.use(CheckboxGroup)
    Vue.use(Carousel)
    Vue.use(CarouselItem)
    Vue.use(Select)
    Vue.use(Option)
    Vue.use(Table)
    Vue.use(TableColumn)
    Vue.use(LoadingCopy)
    Vue.use(Input)
    Vue.use(Collapse)
    Vue.use(CollapseItem)
    Vue.use(Tooltip)
    Vue.prototype.$loading = LoadingCopy.service;
    Vue.prototype.$message = Message;

    Vue.prototype.$confirm = MessageBox
}
}
export default element